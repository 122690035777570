import logo from './assets/images/SalutiLogo.png';
import video from './assets/SalutiBakgrunn.mp4';
import kaffekopp from './assets/images/kaffekopp.jpg';
import './App.css';
import { useState } from 'react';
import { useTransition, animated, useSpring } from 'react-spring'
import { useMediaQuery } from 'beautiful-react-hooks'




function App() {



//Animasjon komponenter start

const [isInPosition, setIsInPosition] = useState(false);
const [move, setMove] = useState(false);

const transition = useTransition(isInPosition, {  
  from: { x: -150, y: 200, opacity:0, width: 10, height: 10, fontSize: 0, backgroundColor: 'white' },
  enter: isInPosition => async (next) => {
  await next ({ y: -100, opacity:1, fontSize: 0 }) 
  await next ({ x: 0, width: 400, height: 150, fontSize:40, backgroundColor: 'rgb(255,255,255,0)' })
  },
  leave: { x: 0, y: 200, opacity:0 },
})


  const isPhoneView = useMediaQuery(
    '(min-width: 800px)'
  )

  const springDesktop = useSpring({
    transform: move ? `translate3d( 350px, 0px, 0px )` : `translate3d( 0px, 0px, 0px )`,
    scale: move ? 0.5 : 1.1
   })

   const springMobile = useSpring({
    transform: move ? `translate3d( 0px, -260px, 0px )` : `translate3d( 0px, 0px, 0px )`,
   })


//Animasjon komponenter slutt

return (

    <div className='background'> 
         
      <div className='app'>

        <div className='container'>


          <div>
            { isPhoneView ? 
              <animated.div className="salutiLogo" style={springDesktop}>
                <img src={logo} alt='logo'></img>
              </animated.div> 
            : 
              <animated.div className="salutiLogo" style={springMobile}>
                <img src={logo} alt='logo'></img>
              </animated.div> 
            }
          </div>
         
          
          
        
          {transition ((style, kontaktInfo) =>
            kontaktInfo ? 
              <animated.div style={style} className='kontaktInfo'>
                <h3>HUGO RØDLI</h3>
                <p>
                  +47 962 25 944 <br></br>
                  <a href="mailto:hugo@saluti.no">hugo@saluti.no</a> 
                </p>
              </animated.div> : ''
            )}
        </div>

        <button className='letsTalkBtn' onClick={() =>{
            setMove(x => !x);
            setIsInPosition(x => !x);
          }}> {isInPosition ? 'Less Talk' : 'Lets Talk'}
        </button>
      </div>
      
    </div>  
  ); 
}


//<animated.video style={springBackground} src={video} autoPlay loop muted></animated.video>

export default App;

